import React, { Component, useState, createRef, useEffect } from "react";

import "./chatContent.css";
import Avatar from "../chatList/Avatar";
import ChatItem from "./ChatItem";

export default class ChatContent extends Component {
  messagesEndRef = createRef(null);
  chatItms = [
    {
      key: 1,
      image:
        "https://pbs.twimg.com/profile_images/1116431270697766912/-NfnQHvh_400x400.jpg",
      type: "",
      msg: "Hi Daisy, How are you?",
    },
    {
      key: 2,
      image:
        "https://s3.amazonaws.com/daisy.images/daisyLogo.png",
      type: "other",
      msg: "I am fine.",
    },
    {
      key: 3,
      image:
        "https://s3.amazonaws.com/daisy.images/daisyLogo.png",
      type: "other",
      msg: "What about you?",
    },
    {
      key: 4,
      image:
        "https://pbs.twimg.com/profile_images/1116431270697766912/-NfnQHvh_400x400.jpg",
      type: "",
      msg: "Awesome these days.",
    },
    {
      key: 5,
      image:
        "https://s3.amazonaws.com/daisy.images/daisyLogo.png",
      type: "other",
      msg: "Great to hear!",
    },
    {
      key: 6,
      image:
        "https://pbs.twimg.com/profile_images/1116431270697766912/-NfnQHvh_400x400.jpg",
      type: "",
      msg: "What is a dollar cost averaging?",
    },
    {
      key: 7,
      image:
        "https://s3.amazonaws.com/daisy.images/daisyLogo.png",
      type: "other",
      msg: "Dollar cost averaging is a method used in investing. Instead of investing a whole lump sum, you split your investment into smaller chunks and buy the asset over time. Eg. Instead of buying $1200 worth of BTC today, you would buy $100 worth of BTC each month.",
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      chat: this.chatItms,
      msg: "",
    };
  }

  scrollToBottom = () => {
    this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  componentDidMount() {
    window.addEventListener("keydown", (e) => {
      if (e.keyCode == 13) {
        if (this.state.msg != "") {
          this.chatItms.push({
            key: 8,
            type: "",
            msg: this.state.msg,
            image:
              "https://pbs.twimg.com/profile_images/1116431270697766912/-NfnQHvh_400x400.jpg",
          });

          this.scrollToBottom();
          

          
          
          fetch(`https://api.alphalibertee.com/response?question=${this.state.msg.replace(" ","%20")}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            
          })
            .then(response => response.text())
            .then(result => {
                  
              
              this.setState({ msg: "" });

              this.chatItms.push({
                key: 9,
                type: "other",
                msg: this.state.msg,
                image:
                  "https://pbs.twimg.com/profile_images/1116431270697766912/-NfnQHvh_400x400.jpg",
              });

              this.scrollToBottom();
              
              
      
              
            
              
      
            })
          
            
      
            
        
          
          this.setState({ chat: [...this.chatItms] });
          
        }
      }
    });
    this.scrollToBottom();
  }
  onStateChange = (e) => {
    this.setState({ msg: e.target.value });
  };

   submitMessage = () => {
    if (this.state.msg != "") {
      this.chatItms.push({
        key: 1,
        type: "",
        msg: this.state.msg,
        image:
          "https://pbs.twimg.com/profile_images/1116431270697766912/-NfnQHvh_400x400.jpg",
      });
      this.setState({ chat: [...this.chatItms] });
      this.scrollToBottom();
      this.setState({ msg: "" });
    }
    this.scrollToBottom();
    this.setState({ msg: "" });
  }

  render() {
    return (
      <div className="main__chatcontent">
        <div className="content__header">
          <div className="blocks">
            <div className="current-chatting-user">
              <Avatar
                isOnline="active"
                image="https://s3.amazonaws.com/daisy.images/daisyLogo.png"
              />
              <p>Daisy</p>
            </div>
          </div>

         
        </div>
        <div className="content__body">
          <div className="chat__items">
            {this.state.chat.map((itm, index) => {
              return (
                <ChatItem
                  animationDelay={index + 2}
                  key={itm.key}
                  user={itm.type ? itm.type : "me"}
                  msg={itm.msg}
                  image={itm.image}
                />
              );
            })}
            <div ref={this.messagesEndRef} />
          </div>
        </div>
        <div className="content__footer">
          <div className="sendNewMessage">
            <button className="addFiles">
              <i className="fa fa-plus"></i>
            </button>
            <input
              type="text"
              placeholder="Type a message here"
              onChange={this.onStateChange}
              value={this.state.msg}
            />
            <button className="btnSendMsg" id="sendMsgBtn" >
              <i className="fa fa-paper-plane"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}
